import * as React from "react"
import { useState } from 'react'
import Helmet from 'react-helmet';

const Button = ({ text, onClick, selected }) => (
  <button
    style={{
      marginRight: '16px',
      marginLeft: '16px',
      border: 'none',
      background: 'none',
      cursor: 'pointer',
      backgroundColor: 'inherit',
      padding: '4px',
      borderRadius: '8px',
    }}
    onClick={onClick}
  >{text}
    {selected && <div style={{
      borderBottom: '1px solid #ddbea9',
      width: '100%',
      marginTop: '3px'
    }} />}</button>

)

const Ingredients = () => (
  <ul style={{margin: 0, padding: 0}}>
    <li>1 1/2 cups self-rising corn meal</li>
    <li>2 eggs</li>
    <li>1 cup milk</li>
    <li>1/2 cup vegetable oil</li>
    <li>1 small can creamed corn</li>
    <li>1 large onion (diced)</li>
    <li>1 or 2 jalapeno peppers (diced)</li>
    <li>1 cup shredded cheese</li>
  </ul>
)

// const recipeNonDairy = (
//   <ul>
//     <li>1 1/2c self-rising corn meal</li>
//     <li>2 Eggs</li>
//     <li>1 Cup unflavored oat or almond Milk</li>
//     <li>1/2 Cup Vegetable Oil</li>
//     <li>1 small can Creamed Corn</li>
//     <li>(optional) 1 large onion diced</li>
//     <li>1 or 2 jalapeno peppers (diced)</li>
//   </ul>
// )

const IndexPage = () => {

  // const [showNonDairy, setShowNonDairy] = useState(false);

  // const ActiveRecipe = () => {
  //   if (showNonDairy) return recipeNonDairy;
  //   return recipeDairy;
  // }

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap" rel="stylesheet" />
      </Helmet>
      <div style={{
        height: '100%',
        fontSize: '30px',

      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <h1 style={{
            marginBottom: '3px',
            fontSize: '2.4rem'
          }}>World's best Cornbread Recipe</h1>
          <div style={{
            minHeight: '350px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '30px'
          }}>
            <h2 style={{ marginBottom: '16px', display: 'none' }}>Ingredients</h2>
            {/* <div style={{
              display: 'flex',
            }
            }>
              <Button text="Dairy" selected={!showNonDairy} onClick={() => setShowNonDairy(false)} />
              <Button text="Non-Dairy" selected={showNonDairy} onClick={() => setShowNonDairy(true)} />
            </div> */}
            <Ingredients />
          </div>
          <p style={{ textAlign: 'center' }}>Mix all Ingredients. Bake at <b>350°</b> for <b>45min</b> in a cast iron skillet</p>
        </div>
        <div style={{
          position: 'relative',
          bottom: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          height: '60px'
        }}>
          <a href="https://www.hayesfuneralhome.net/obituary/deborah-harrell" style={{ fontSize: '21px', marginTop: 'auto', textDecoration: 'none',  color: 'inherit' }}>In memory of Deborah Lynn Harrell</a>
        </div>
      </div>
    </>


  )
}

export default IndexPage;